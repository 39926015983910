<template>
  <div class="About">
    <div>
        <img src="@/assets/yibo/3/NvpMGd6hDcnbPwyZUtwCeP@2x.png" alt="" style="width:100%;height:auto;">
    </div>
    <div class="About_content">
        <div class="moduleTwo">
            <div style="padding-top:20px;">
                <div class="conT2">
                    <!-- <div class="title">同通科技三大核心产品</div>
                    <div class="subtitle">打造智慧停车全生态系统运用</div> -->
                </div>
                <div>
                    <div style="width:100%;">
                        <img src="@/assets/yibo/3/组 496@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="moduleThree">
            <div style="padding-top:20px;">
                <div class="conT2">
                  <!-- <div class="title">同通停车产品优势</div>
                  <div class="subtitle">领先软硬件配置 完整的运维系统</div> -->
                </div>
                <div>
                    <div style="width:100%;">
                        <img src="@/assets/yibo/3/组 497@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="content2">
                <div class="conT2">
                    <!-- <div class="title">智慧停车新形态</div>
                    <div class="subtitle">智慧创新 未来可期</div> -->
                </div>
                <div class="content2_img">
                    <div style="width:100%;">
                        <img src="@/assets/yibo/3/组 498@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="content2">
                <div class="conT2">
                    <!-- <div class="title">智慧停车新形态</div>
                    <div class="subtitle">智慧创新 未来可期</div> -->
                </div>
                <div class="content2_img">
                    <div style="width:100%;">
                        <img src="@/assets/yibo/3/组 499@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="content2">
                <div class="conT2">
                    <!-- <div class="title">智慧停车新形态</div>
                    <div class="subtitle">智慧创新 未来可期</div> -->
                </div>
                <div class="content2_img">
                    <div style="width:100%;">
                        <img src="@/assets/yibo/3/组 500@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="main">
            <div class="between">
              <div class="leftContent">
                <div class="conT2">
                    <div class="title">无感支付系统</div>
                    <div class="subtitle">授权支付提前扫码付费 极速无感出场</div>
                </div>
                <div class="content">无感支付是基于微信支付、支付宝的免密支付底层能力，深度贴合停车行业，为停车场景开发的专属解决方案。</div>
                <div class="content">用户开通无感支付，并进入无感支付停车后，不仅可以实现无现金电子支，还可以无手机，离场自动缴费、自动抬杆。</div>
                <div class="content">通过接入无感支付，给用户带来极致的停车体验，也可以将停车场的通行效率提升6倍以上，实现管理水平的飞跃。</div>
                <div class="spanView"><span>无麻烦</span><span>无需IC卡</span><span>无需值守</span><span>无疏漏</span><span>无复杂</span></div>
              </div>
              <div class="img">
                <img src="@/assets/yibo/3/组 1981@2x.png" alt="" style="width:100%">
              </div>
            </div>
        </div> -->
  </div>
</div>
</template>

<script>
import about from '@/api/about.js'
export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  data(){
    return {
        introd: {}
    }
  },
  created(){
    // this.initIntrod()
  },
  methods:{
    initIntrod(){
        about.Query({}).then(resp=>{
            if(resp.data.code == 200){
                console.log(resp.data.data.data,"简介")
                this.introd = resp.data.data.data
            }
            
        })
    }
  }
  
};
</script>
<style scoped lang="less">
.conT2 {
  padding: 10px 0;
  margin: 50px 0;
  text-align: center;
  .title {
    font-size: 34px;
    font-weight: bold;
    color:#000000FF;
    height: 45px;
    line-height: 35px;
  }
  .subtitle {
    font-size: 14px;
    color:#999999FF;
    height: 19px;
    line-height: 35px;
  }
  .line {
    width: 30px;
    height: 20px;
    margin: 0 auto;
    border-bottom: 6px solid #287bffff;
  }
}
.About {
  background: #FFFFFFFF;
  .About_content {
    width: 60%;
    margin: 0 auto;
    .main {
      margin-bottom: 20px;
    //   background-color: red;
      margin: 0 auto;
      &_content {
        padding: 20px 0;
        &_text {
            div{
                text-indent: 1rem;
                font-size: 18px;
                color: rgba(51, 51, 51, 1);
                text-align: left;    
            }
        }
        .concat {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            color: rgba(51, 51, 51, 1);
            font-size: 18px;
            font-weight: 400;
            &_one {
                margin-bottom: 30px;
                img{
                    vertical-align: middle;
                }
            }
        }
      }
    }
    .moduleThree {
        // width: 1200px;
        // width: 60%;
        // min-width: 1200px;
        margin: 0 auto;
    }
    .main2 {
      margin-bottom: 20px;
    //   width: 1200px;
    //   width: 60%;
    //   min-width: 1200px;
      margin: 0 auto;
      .content2 {
        .content2_img {
            display:flex;
            flex-wrap: wrap;
            .item {
                width:calc(100% / 2);
                img {
                    width:100%;
                    height:auto
                }
            }
        }
      }  
    }
  }

  
}
                    
.between{
  display: flex;
  justify-content: space-between;
  text-align: left;
  .leftContent{
    width: 60%;
  }
  .conT2 {
    text-align: left;
  }
  .content{
    line-height: 28px;
  }
  .spanView{
    margin-top: 50px;
    span::before{
      content: " ●";
      color: blue;
    }
    span{
      margin-right: 10px;
    }
  }
  .img{
    flex: 1;
    margin-top: 50px;
    margin-left: 30px;
  }
}

</style>
